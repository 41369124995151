.error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
    text-align: center;
    margin-bottom: 60px;
  }
  .error h1 {
    font-family: title;
    font-size: 400px;
    color: #000;
  }
  .error p {
    font-family: title;
    font-size: 63px;
    color: #000;
  }
  .error span {
    font-family: regular;
    font-size: 31px;
    color: #9b9b9b;
    max-width: 850px;
    width: 100%;
    text-align: center;
  }
  .error a {
    display: block;
    width: 100%;
    text-align: center;
  }
  .error button {
    color: #fff;
    background: #000;
  
    margin-top: 20px;
    border: 1px solid #000;
    max-width: 520px;
    width: 100%;
    height: 70px;
  }
  @media(max-width: 992px){
    .error h1 {
        font-size: 250px;
      }
      .error p {
        font-size: 35px;
      }
      .error button {
        width: 300px;
        height: 50px;
      }
  }
  @media(max-width: 576px){

  .error h1 {
    font-size: 130px;
  }
  .error p {
    font-size: 20px;
  }
  .error span {
    font-size: 14px;
  }
}