.header-background {
  background-color: #1d7ecb;
}
.header_container {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.bg-gray-100 {
  background-color: #f7fafc;
}
.text-gray-text {
  color: #718096;
  font-size: 17px;
}
.text-gray-point {
  color: #718096;
  font-size: 18px;
  font-weight: 600;
}
.max-w-1350 {
  max-width: 1000px;
}
@media (max-width: 650px) {
  .mySpan {
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .first_style h1 {
    font-size: 1.5rem;
  }
  .first_style {
    padding: 1rem 0.5rem;
  }
  .header_container {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
