.banner_text {
  justify-content: space-between;

  box-sizing: border-box;
}
.calculate {
  min-width: 430px;
  border: 1px solid #ffffff30;
  border-radius: 45px;
  box-sizing: border-box;
  clip-path: unset !important;
}
.calculate-inner {
  border: 1px solid #ffffff30;
  border-radius: 45px;
  box-sizing: border-box;
  clip-path: unset !important;
}
.monoDb {
  clip-path: unset !important;
}
.titleText {
  text-transform: uppercase;
}

.bigCalculater {
  clip-path: unset !important;
  box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.7),
    inset -1px -1px 2px rgba(255, 255, 255, 0.7);
}
.forCookie {
  position: fixed;
  max-width: 400px;
  bottom: 30px;
  right: 20px;
  padding: 20px;
  background: #f4f4f4;
  text-align: center;
  z-index: 10;
  border-radius: 30px;
  opacity: 0;
  animation: anim 4s forwards;
}
@keyframes anim {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.forCookie .cookieTitle {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  margin: 0 auto;
}
.falseCookie {
  display: none;
}
.cookiebtn {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
.cookiebtn button {
  margin-top: 20px;
  max-width: 500px;
  min-width: 300px;
  width: 100%;
  height: 64px;
  font-weight: 600;
  border-radius: 30px;
  font-size: 18px;
  background: #cce3fc;
  padding: 10px;
  border: 0;
  margin-left: 5px;
  margin-right: 5px;
}
.cookiebtn button:nth-child(2) {
  background: #41556e;
  color: #fff;
}
.cookiebtn button:nth-child(1) {
  background: #dfe4eb;
  color: #000;
}
.forShadow {
  border-radius: 60px;
  clip-path: unset !important;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1);
}
@media (max-width: 850px) {
  .banner_text {
    flex-direction: column;
  }
  .calculate {
    max-width: 502px;
    min-width: unset;
    border-color: transparent;
    padding: 1.5% 0;
  }
  .calculate div {
    border-width: 0px;
  }
  .calculate-inner {
    padding: 5% 0;
    max-width: 400px;
    width: 100%;
  }
  .home_text {
    font-size: 24px;
    line-height: 140%;
  }

  .cookiebtn button {
    height: 62px;
    font-size: 14px;
    margin-top: 12px;
  }
  .forCookie .cookieTitle {
    font-size: 14px;
  }
}
@media (max-width: 450px) {
  .forCookie {
    max-width: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
  .cookiebtn {
    flex-direction: column-reverse;
  }
}
