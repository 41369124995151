.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text-contact {
  color: #272727;
}
.heading-contact {
  font-weight: 700;

  margin-top: 60px;
  text-transform: uppercase;
}
.heading-main {
  color: #f8d308;
}
.input-form {
  width: 100%;
  height: 56px;
  border-radius: 20px;
  padding-left: 10px;
  outline: none;
  border: 1px solid #e0e0e0;
  display: block;
  margin-bottom: 20px;
}

.heading-about {
  text-align: center;
  margin-bottom: 40px;
}

.label-form {
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  font-weight: 700;
  opacity: 60%;
  font-size: 14px;
  color: #000000;
  margin-left: 15px;
  margin-bottom: 4px;
}

.textarea-form {
  min-height: 97px;
  resize: vertical;
}

.button-form {
  border-radius: 20px;
  background: #272727;
  color: #e0e0e0;
  width: 100%;
  height: 56px;
}
.formContainer {
  position: relative;
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
}
.form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
}
.bootomText {
  margin-top: 80px;
  margin-bottom: 80px;
}
.bootomText .text {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #000000;
  opacity: 70%;
}

.blueText {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: center;
}
.sentMessage {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.sentMessage span {
  max-width: 500px;
  width: 100%;
  border: 1px dotted #272727;
  padding: 20px;
  border-radius: 20px;
  background: #acee5b;
}
@media (max-width: 500px) {
  .formContainer {
    padding-left: 10px;
    padding-right: 10px;
  }
  .feedback img {
    height: 300px;
  }
  .heading-contact {
    font-size: 18px;
    padding: 10px;
  }
  .sentMessage  {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 400px) {
  .feedback img:nth-child(1) {
    height: 250px;
    top: 200px !important;
  }
  .feedback img:nth-child(2) {
    height: 250px;
    top: 250px !important;
  }
}
