.noWrapText {
  text-align: right;
}
.myButtonStyle {
  color: #000;
  width: 250px;
  height: 58px;
  font-size: 16px;
  border-radius: 20px;
}
.myMob_ButtonStyle {
  color: #000;
  height: 48px;
}
.newFont {
  font-size: 28px;
}
.smartClass {
  justify-content: space-between;
  width: 100%;
}
.smartspan {
  color: #fff;
  border-radius: 7px;
  background: #ff0000;
  padding: 0px 7px;
  display: flex;
  height: 23px;
  line-height: 23px;
  font-size: 14px;
}
.smartspan svg {
  height: 23px;
}

@media (max-width: 920px) {
  .newFont {
    font-size: 20px;
  }
  .myButtonStyle {
    width: 220px;
  }
}
@media (max-width: 576px) {
  .pay_method {
    width: 100%;
  }
  .pay_method span:nth-child(1) {
    width: 70%;
  }
  .noWrapText {
    font-size: 16px;
    white-space: nowrap;
  }
  .fontmysize {
    font-size: 10px;
  }
  .otherText {
    font-size: 24px;
  }
  .smartspan {
    font-size: 8px;
    line-height: 16px;
    height: 16px;
    padding: 0 4px;
  }
  .smartspan svg {
    height: 16px;
    width: 14px;
    padding-left: 2px;
  }
}
