.slideC {
  position: relative;
  perspective: 2000px;
  width: 400px;
  height: 560px;
  margin: 0 auto;
  margin-top: 60px;

}

.slide {
  width: 400px;
  height: 560px;
  transition: transform 500ms ease 0s, opacity 500ms ease 0s,
    visibility 500ms ease 0s;
  position: absolute;
  top: 0;
  border-radius: 60px;
}

.reflection {
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: -60px;
  border-radius: 12px;
  transition: transform 500ms ease 0s, opacity 500ms ease 0s,
    visibility 500ms ease 0s;
}

.sliderContent {
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
}

.btns {
  padding-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
}

.image {
  width: 50px;
  height: 50px;
  margin-left: 0px;
  margin-right: 20px;
}

.desc {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.022em;
  text-align: center;
}

.icon {
  width: 260px;
  margin-top: 120px;
  margin-bottom: 180px;
}
.truncate-multiline {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 1350px) {
  .slideC {
    position: relative;
    perspective: 2000px;
    width: 260px;
    height: 390px;
    margin-top: 60px auto;
    /* overflow: hidden; */
  }

  .slide {
    width: 260px;
    height: 390px;
    transition: transform 500ms ease 0s, opacity 500ms ease 0s,
      visibility 500ms ease 0s;
    position: absolute;
    top: 0;
    border-radius: 40px;
  }

  .icon {
    width: 148px;
    margin-top: 120px;
    margin-bottom: 60px;
    height: 30px;
  }

  .desc {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.022em;
    text-align: center;
  }

  .image {
    width: 30px;
    height: 30px;
    margin-left: 0px;
    margin-right: 20px;
  }
  
}
@media screen and (max-width: 1350px) {
.slideC {
  margin: 60px 0 0;
}
}