@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.containerBig {
  max-width: 1300px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* padding-left: 10px;
  padding-right: 10px; */
}
.text-custom {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600; /* Semi Bold */
  color: #363636;
  letter-spacing: -0.05em; /* Это для -5% */
}
.text-custom1 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600; /* Semi Bold */
  color: #686868;
  letter-spacing: -0.05em; /* Это для -5% */
}
.text-custom-mod {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #3b3b3b;
  letter-spacing: -0.05em; /* Это для -5% */
}
.custom-info-modal .ant-modal-content {
  border-radius: 10px;
  padding: 20px;
  max-width: 350px;
  width: 100%;
  box-sizing: border-box;
  color: #000000;
  padding: 20px 0;
}

.custom-info-modal .ant-modal-close {
  display: none;
}

.custom-modal .ant-modal-content {
  border-radius: 30px;
  overflow: hidden;
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;
}

.common-info-style {
  background-color: #ffffff;
  color: black;
  text-align: center;
  border-radius: 30px;
  padding: 20px;
  font-size: 12px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 350px;
  min-width: 330px;
  z-index: 1000;
}

.common-info-style .close-icon {
  cursor: pointer;
  display: flex;
  justify-content: end;
  margin: 10px 0;
}

.common-info-style .info-text {
  text-align: left;
  color: black;
  margin-bottom: 20px; /* Увеличиваем отступ снизу */
}

.common-info-style .info-content {
  text-align: left;
  color: black;
  margin-bottom: 8px; /* Увеличиваем отступ снизу */
}

/* :where(.css-1kuana8).ant-modal .ant-modal-content{
  background-color:  transparent;
} */
.menunewclass {
  padding-left: 2px;
  padding-right: 2px;
  justify-content: flex-end;
}
.calculatorSmall {
  max-width: 400px;
  margin: 50px auto;
  box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.4);
}
.newHeaderModal {
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newHeaderModal .modalText h3 {
  text-align: center;
  font-weight: 900;
  margin-bottom: 20px;
}
.newHeaderModal .modalText {
  max-width: 550px;
  width: 100%;
  padding: 15px;
  border-radius: 30px;
  box-sizing: border-box;
  background: #eee;
}

body {
  padding-right: unset !important;
}
.chooseCantry svg {
  opacity: 0;
}
.method_content {
  padding-top: 30px;
  padding-bottom: 150px;
}
.bgNew {
  background-color: rgb(223 236 251);
}
@media (max-width: 768px) {
  .scrollmy {
    margin: 5px;
  }
}
@media (max-width: 560px) {
  .newHeaderModal .modalText {
    max-width: 420px;
    margin-top: 50px;
  }
  .mobilecard {
    margin-left: 0;
    margin-right: 0;
  }
  .mobileSeletCountry {
    width: 32px;
    height: 32px;
  }
  .scrollmy{
    margin-top: 40px;
  }
}
@media (max-width: 400px) {
  .newHeaderModal .modalText {
    margin-top: 40px;
    max-width: 300px;
  }
  .langBtn {
    margin-left: 5px;
  }
  .langmenu {
    padding-right: 2px;
  }
  .rightArrow {
    margin-left: 5px;
    margin-right: 5px;
  }
  .langBtn {
    width: 90px;
  }
  .menuclassdiv {
    padding: 10px 2px;
    max-width: 210px;
  }
  .menuclassdiv input {
    padding-left: 5px;
    max-width: 180px;
  }
}
@media (max-width: 370px) {
  .langright {
    padding-right: 1px;
  }
  .langleft {
    padding-left: 1px;
  }
  .langleft div {
    justify-content: center;
  }

  .langBtn img {
    margin-right: 2px;
  }

  .truncate {
    overflow: unset;
  }
}
