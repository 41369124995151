.myContainer {
  max-width: 1000px;
  width: 100%;
  margin: 2% auto;
  padding-left: 10px;
  padding-right: 10px;
}
.truncateP {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
/* :where(.css-1kuana8).ant-modal .ant-modal-content{
    background-color:  transparent;
  } */
.bredcrumbText h1 {
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
  opacity: 0.8;
  text-align: left;
  margin-top: 10px;
}
.bredcrumbText h1 span {
  opacity: 1;
  text-transform: uppercase;
}
.bannerCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.whitSide {
  max-width: 320px;
  width: 100%;
  background: #fff;
  border-radius: 20px;
  padding: 10px;
}

.whitSide p {
  font-size: 20px;
}
.imgSid {
  max-width: 660px;
  width: 100%;
  height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-left: 20px;
}

.imgSid img {
  max-width: 660px;
  width: 100%;
  height: auto;
  border-radius: 20px;
  max-height: 440px;
}
.revieSlid img {
  border-radius: 0px;
}
.socialIcons {
  display: flex;
  justify-content: flex-end;
}
.bannerLastBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 45px;
}
.dayMonth {
  display: flex;
  justify-content: center;
  width: 70%;
}
.dayMonth p {
  color: #fff;
}
.internationaltext {
  max-width: 660px;
  width: 100%;
}
.internationaltext ul {
  text-align: left;
  padding: 5px 0;
}
.internationaltext ol {
  text-align: left;
  padding: 5px 15px;
}
.internationaltext ol li {
  list-style: inside;
  font-size: 20px;
  text-align: left;
  list-style: decimal;
}
.internationaltext ul li {
  list-style: inside;
  padding: 0 15px;
  text-align: left;
  font-size: 20px;
}
.combineBlock {
  max-width: 660px;
  width: 100%;
  margin-left: 20px;
}

.myContainer .myCalculate .myButton {
  background: #ffc737;
}
.myContainer .myCalculate .notShowOnPage {
  display: none;
}
.myContainer .myCalculate .compear {
  border: 1px solid #4a9ce2;
  border-radius: 20px;
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.myContainer .myCalculate .compear img {
  margin-top: 10px;
}
.internationaltext p {
  padding: 15px;
  background: #daebf9;
  border-radius: 30px;
  font-size: 20px;
  line-height: 150%;
  text-align: left;
}
.internationaltext h2 {
  line-height: 130%;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 25px;
}
.internationaltext img {
  padding: 15px;
}
.secondBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
}
.secondBlock .bigCalculater {
  background: #2485c9;
}
.ordinaryText h3 {
  /* opacity: 0.7; */
  font-size: 24px;
  font-weight: 700;
  margin-top: 35px;
  margin-bottom: 35px;
}
.ordinaryText p {
  /* opacity: 0.7; */
  font-size: 20px;
  line-height: 150%;
  background: none;
}
.registerSkrill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: #daebf9;
  border-radius: 30px;
  border: 1px solid #4a9ce2;
  margin-top: 30px;
  margin-bottom: 30px;
}
.registerSkrill h3 {
  font-size: 24px;
  font-weight: 700;
}
.registerSkrill p {
  background: unset;
  font-weight: 500;
}
.registerSkrill button {
  background: #ffc737;
  width: 250px;
  height: 58px;
  border-radius: 20px;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;
}
.registerSkrill span {
  opacity: 0.7;
  margin-bottom: 20px;
}
.ordinaryText a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ordinaryText button {
  background: #ffc737;
  width: 265px;
  height: 58px;
  border-radius: 20px;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ordinaryText button img {
  margin-right: 10px;
}
.video {
  cursor: pointer;
  max-width: 660px;
  width: 100%;
  border-radius: 20px;
  margin: 30px auto;
}
.interestedIn h4 {
  text-align: left;
  font-size: 24px;
  opacity: 0.7;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 30px;
}
.interestedBlock {
  display: flex;
  flex-wrap: wrap;
}
.interestedBlock a {
  flex: 0 1 31%;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
}
.interestedBlock p {
  background: unset;
  font-size: 14px;
  color: #2b2b2b;
  padding: 10px;
  height: 78px;
  text-align: left;
  font-weight: 600;
}
.interestedBox span {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin-top: 15px;
}
.interestedBox span img {
  margin-right: 10px;
  height: 15px;
  width: 13px;
}
.interestedBox .littleP {
  height: 40px;
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

}
.interestedBox .littleP,
.interestedBox span {
  font-size: 11px;
  font-weight: 400;
}
.interestedBox img {
  border-radius: 10px;
  width: 100%;
  height: 208px;
}
.interestedBox {
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.dialogIframe {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.dialogIframe iframe {
  max-width: 660px;
  width: 100%;
  height: 440px;
  border-radius: 20px;

  margin: 5% auto;
}
.dialogIframe svg {
  cursor: pointer;
}
.ordinaryText span {
  display: block;
  font-size: 16px;
  opacity: 0.7;
  text-align: left;
}
.downloadIcons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.downloadIcons img {
  margin-left: 10px;
  margin-right: 10px;
}
.showBlock {
  display: block;
}
.showBlock .calculatorSmall {
  margin: 10px auto;
}
.hideBlock {
  display: none;
}

.hideTitle {
  display: none;
}
.showTitle {
  display: block;
}
.hideIcon {
  display: none;
}
/* .showIcon{
    display: block;
} */
.imgSid .reviewImg {
  max-width: 247px;
}
.revieSlid {
  max-width: 660px;
  width: 100%;
  height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #daebf9;
}

@media (max-width: 992px) {
  .showBlock {
    display: none;
  }
  .hideBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
  }
  .hideBlock p {
    background: unset;
  }
  .hideBlock div {
    max-width: 320px;
  }
  .calculatorSmall {
    margin: 10px auto;
  }
  .interestedBlock {
    margin-bottom: 20px;
  }
  .dialogIframe iframe {
    margin: 30px auto;
  }

  .internationaltext {
    max-width: 900px;
    margin-left: 0;
  }
  .secondBlock {
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media (max-width: 800px) {
  .showTitle {
    display: none;
  }
  .hideTitle {
    display: block;
    margin-top: 30px;
  }
  .hideTitle h2 {
    color: #fff;
    font-size: 28px;
    line-height: 120%;
  }
  .bannerCont {
    flex-direction: column-reverse;
  }
  .imgSid {
    margin-left: unset;
    height: 300px;
    /* box-shadow: inset 1px 1px 2px #fff; */
  }
  .revieSlid {
    margin-left: unset;
    height: 300px;
    box-shadow: inset 1px 1px 2px #fff;
  }
  .imgSid img {
    max-height: 280px;
  }
  .reviewImg img {
    max-height: 280px;
  }
  .blogbackground {
    max-height: max-content;
    margin-bottom: 60px;
  }
  /* .internationaltext{
    margin-top: 130vh;
  } */
  .hideIcon {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .dayMonth {
    width: unset;
  }
  .combineBlock {
    margin-left: 0;
  }
  .showIcon {
    display: none;
  }
  .whitSide {
    border: 1px solid #e0e0e0;
  }
}
@media (max-width: 640px) {
  .interestedBlock a {
    flex: 0 1 46%;
  }
  .imgSid {
    height: 190px;
  }
  .imgSid img {
    max-height: 190px;
  }
  .reviewImg img {
    max-height: 190px;
  }
  .socialIcons img {
    width: 30px;
    height: 30px;
  }
  /* .interestedBlock p,
  .interestedBlock .littleP {
    height: auto;
  } */
  .dialogIframe iframe {
    height: 264px;
  }
  .internationaltext ul li,
  .internationaltext ol li {
    font-size: 16px;
  }
  .ordinaryText h3 {
    font-size: 20px;
  }
  .ordinaryText p {
    font-size: 16px;
  }
  .interestedBox p {
    height: 78px;
  }
  .truncateP {
    -webkit-line-clamp: 3;
  }
}
@media (max-width: 430px) {
  .interestedBox img {
    height: 150px;
  }
}
