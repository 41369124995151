.truncate-multiline {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
.regular-truncate-multiline {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  height: 75px;
  padding-bottom: 18px;
  text-align: left;
  margin-left: 8px;
}
.popular-truncate-multiline {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Number of lines to show */
  -webkit-box-orient: vertical;
  text-align: left;
}
.popular-truncate-multiline_1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
.popular-truncate-multiline_17 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 17; /* Number of lines to show */
  -webkit-box-orient: vertical;
  text-align: left;
}

.popular-truncate-text {
  color: #011a3f;
  font-size: 24px;
  font-weight: 700;
  margin-left: 5px;
  display: flex;
  text-align: left;
  justify-content: flex-start;
  line-height: 32px;
}
.popText {
  clip-path: unset !important;
}
.regular_text span p,
.regular_text {
  font-size: 16px !important;
  text-align: left;
  color: #00333e !important;
  margin-top: 10px;
}
.poptext {
  text-shadow: 1px 1px 3px #000;
  line-height: 120%;
}
.popular0,
.popular1 {
  /* border: 1px solid #000; */
  border-radius: 42px;
}
.popular-truncate-text1 {
  color: #011a3f;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.middle_img {
  height: 380px;
  border-radius: 42px;
}
.rewiew_box {
  border: 1px solid #eee;
  box-shadow: inset -1px -1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 60px;
  max-width: 500px;
}

.rewieSlider img {
  height: auto;
  margin-top: 80px;
  margin-bottom: 30px;
  max-width: 200px;
}
.maxHeigtforRevie {
  max-width: 350px;
}
.rewieSlider .btns {
  padding-top: unset;
}
.newClass {
  border-radius: 60px;
  clip-path: unset !important;
  height: auto;
}
.forShadow {
  border-radius: 60px;
  clip-path: unset !important;
}
@media (max-width: 1280px) {
  .my_order {
    display: flex;
    flex-direction: column;
  }
  .newClass {
    margin-top: 20px;
  }

  .my_order .myFirstDIV {
    order: 2;
  }
  .my_order .mySecondDIV {
    order: 1;
  }
  .my_order .myThirdDIV {
    order: 3;
  }
  .my_order .myThirdDIV img {
    min-height: 250px;
    height: 100%;
    object-fit: cover;
  }
  .mobile_change {
    flex-direction: column;
    height: auto;
  }
  .mobile_change div {
    width: 100%;
    text-align: left;
  }
  .mobile_change div img {
    height: 360px;
  }
  .my_order .mySecondDIV .regular_text {
    padding-left: 7px;
  }
  .poptext {
    font-size: 24px;
  }
}
@media (max-width: 576px) {
  .mobile_change div img {
    height: 236px;
  }
  .myThirdDIV img {
    width: 100%;
    height: 236px;
  }
  .newClass div {
    height: auto;
  }
  .newClass img {
    width: 100%;
    height: 236px;
  }
  .maxHeigtforRevie {
    height: 300px;
  }
  .maxHeigtforRevie img {
    height: 180px;
  }
  .rewieSlider {
    overflow: hidden;
  }
  .regular-truncate-multiline {
    -webkit-line-clamp: 4;
  }
}

@media (max-width: 460px) {
  .maxHeigtforRevie img {
    height: 120px;
  }
  .maxHeigtforRevie {
    height: 280px;
  }
  .regular-truncate-multiline {
    height: 95px;
  }
}
